import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import dropArrow from "../../../images/drop-arrow.png";
import { useSearchResultsStore } from "../../../services/store";
import { pluralise } from "../../../helpers";

// get from database somehow
const regions = [
  {
    label: "Cumbria",
    value: 1,
  },
  {
    label: "North West",
    value: 2,
  },
  {
    label: "Derbyshire",
    value: 3,
  },
  {
    label: "North Wales",
    value: 4,
  },
];

export default function RegionSelector({ hasMap }) {
  const useStyles = makeStyles((theme) => ({
    wrapper: {
      position: "relative",
      minWidth: "200px",
    },
    singleRegion: {
      display: "flex",
      alignItems: "center",
      gap: ".5rem",
      width: "100%",
      padding: ".25rem",

      cursor: "pointer",
      transition: "all 0.2s ease-in-out",

      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    },
    dropdown: {
      position: "absolute",
      top: "100%",
      zIndex: "1",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      overflow: "hidden",
      backgroundColor: "#fff",
      boxShadow:
        "0 2px 4px rgba(0, 0, 0, 0.15), 0 4px 16px rgba(0, 0, 0, 0.05)",
      transition: "all 0.2s ease-in-out",
      pointerEvents: "none",
      opacity: 0,
      maxHeight: 0,
    },
    dropdownOpen: {
      pointerEvents: "auto",
      opacity: 1,
      maxHeight: "200px",
    },
    input: {
      position: "relative",
      border: "none",
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      backgroundColor: "white",
      fontSize: 12,
      fontFamily: theme.bodyFont,
      padding: "15px 20px",
      height: "auto !important",
      width: "100%",
      borderRadius: 0,
      overflow: "hidden",
      lineHeight: "1.1",
      whiteSpace: "nowrap",
      maxWidth: "200px",
      textOverflow: "ellipsis",
      color: "gray",
      backgroundImage: "url(" + dropArrow + ")",
      backgroundSize: 18,
      backgroundPosition: "center right 10px",
      backgroundRepeat: "no-repeat",
      paddingRight: 38,
      cursor: "pointer",
    },
  }));
  const classes = useStyles();

  const [isDropdownOpen, setIsDropdownOpen] = useState(hasMap);

  const {
    selectedRegions,
    addSelectedRegion,
    removeSelectedRegion,
  } = useSearchResultsStore();

  function handleChange(e) {
    const { name, checked } = e.target;

    if (checked) {
      addSelectedRegion(name);
    } else {
      removeSelectedRegion(name);
    }
  }

  const selectedRegionsAsText = selectedRegions
    .map((region, index) => {
      const matchedRegion = regions.find((item) => item.value === region);
      if (!matchedRegion) return null;
      return `${matchedRegion.label}${
        index !== selectedRegions.length - 1 ? ", " : ""
      }`;
    })
    .join("");

  return (
    <div
      className={classes.wrapper}
      onMouseLeave={() => setIsDropdownOpen(false)}
    >
      <div
        className={clsx(`select-mimic`, classes.input)}
        title={selectedRegionsAsText || "Select Region"}
        onClick={() =>
          setIsDropdownOpen((prevIsDropdownOpen) => !prevIsDropdownOpen)
        }
      >
        {selectedRegions.length > 0
          ? `${selectedRegions.length} ${pluralise(
              "Region",
              selectedRegions.length
            )} selected`
          : "Select Region"}
      </div>

      <div
        className={clsx(
          `dropdown-mimic`,
          classes.dropdown,
          isDropdownOpen ? classes.dropdownOpen : false
        )}
      >
        {regions.map((region, i) => (
          <label
            key={i}
            htmlFor={region.value}
            className={classes.singleRegion}
            data-active={selectedRegions.includes(region.value.toString())}
            data-all={selectedRegions}
          >
            <input
              type="checkbox"
              id={region.value}
              name={region.value}
              defaultChecked={selectedRegions.includes(region.value.toString())}
              onChange={handleChange}
            />
            <span>{region.label}</span>
          </label>
        ))}
      </div>
    </div>
  );
}
