import whitePin from "../../images/white-pin.png"; // eslint-disable-line
import whiteBed from "../../images/white-bed.png"; // eslint-disable-line
import arrow from "../../images/link-arrow.png";

export default (theme) => ({
  results: {
    "& p": {
      "& button": {
        display: "inline-block",
        border: "none",
        backgroundColor: "transparent",
        borderBottom: "2px solid " + theme.palette.primary.main,
        color: theme.palette.primary.main,
        padding: "0 0 4px",
        fontFamily: theme.headerFont,
        textTransform: "uppercase",
        letterSpacing: "0.04em",
        transition: "color .3s ease-in-out, border-color .3s ease-in-out",
        outline: "none !important",
        marginLeft: 12,
        "&:hover, &:focus": {
          color: theme.palette.secondary.main,
          borderColor: theme.palette.secondary.main,
        },
      },
    },
  },
  NoResults: {
    "& h2": {
      margin: 0,
      textTransform: "uppercase",
      letterSpacing: "0.04em",
      fontSize: 18,
      [theme.breakpoints.up("md")]: {
        fontSize: 24,
      },
    },
    "& p": {
      lineHeight: 2,
      fontSize: 14,
      maxWidth: 860,
      "& a": {
        color: theme.palette.primary.main,
        transition: ".3s ease-in-out color",
        "&:hover, &:focus": {
          color: theme.palette.primary.dark,
        },
      },
    },
  },
  ambiguousResults: {
    opacity: 0,
    height: 0,
    overflow: "hidden",
    transition: "opacity .3s ease-in-out",
    "&.show": {
      opacity: 1,
      height: "auto",
    },
  },
  Header: {
    fontSize: 18,
    textTransform: "uppercase",
  },
  PlaceLinks: {
    marginBottom: 50,
    "& button": {
      display: "table",
      border: "none",
      backgroundColor: "transparent",
      borderBottom: "2px solid " + theme.palette.primary.main,
      color: theme.palette.primary.main,
      padding: "0 0 6px",
      fontFamily: theme.headerFont,
      textTransform: "uppercase",
      letterSpacing: "0.04em",
      transition: "color .3s ease-in-out, border-color .3s ease-in-out",
      outline: "none !important",
      marginBottom: 12,
      "&:hover, &:focus": {
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
      },
    },
  },
  Underline: {
    flex: "1",
    marginLeft: theme.spacing(1),
    borderBottom: `3px solid ${theme.palette.common.black}`,
  },
  Card: {
    margin: "0 10px 20px",
    boxShadow: "0 3px 8px rgba(0,0,0,0.16)",
    borderBottom: "2px solid black",
    borderTop: "2px solid black",
    "& .actions": {
      [theme.breakpoints.up("md")]: {
        backgroundColor: theme.palette.primary.main,
        gap: 1,
      },
    },
  },
  CardUpper: {
    position: "relative",
    fontSize: 0,
  },
  CardInfo: {
    position: "absolute",
    left: 0,
    bottom: 0,
    zIndex: 2,
    height: 60,
    background:
      "linear-gradient(to top, rgba(0,0,0,1) 0%,rgba(0,0,0,0.4) 40%,rgba(0,0,0,0) 100%);",
    width: "100%",
    padding: "25px 20px 0",
    [theme.breakpoints.up("md")]: {
      padding: "25px 30px 0",
    },
  },
  CardDistance: {
    backgroundImage: "url(" + whitePin + ")",
    backgroundPosition: "center left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: 15,
    lineHeight: "15px",
    paddingLeft: 20,
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: theme.headerFont,
    color: theme.palette.secondary.contrastText,
    margin: 0,
    marginRight: 24,
    display: "inline-block",
    [theme.breakpoints.up("lg")]: {
      height: 18,
      fontSize: 15,
      lineHeight: "18px",
    },
    "& span": {
      fontStyle: "italic",
      fontFamily: theme.bodyFont,
      fontWeight: "normal",
    },
  },
  Tenancy: {
    backgroundImage: "url(" + whiteBed + ")",
    backgroundPosition: "center left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: 15,
    lineHeight: "15px",
    paddingLeft: 30,
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: theme.headerFont,
    color: theme.palette.secondary.contrastText,
    margin: 0,
    display: "inline-block",
    fontStyle: "italic",
    fontFamily: theme.bodyFont,
    fontWeight: "normal",
    [theme.breakpoints.up("lg")]: {
      height: 18,
      fontSize: 15,
      lineHeight: "18px",
      paddingLeft: 38,
    },
  },
  CardBottom: {
    padding: "20px 24px",
  },
  CardName: {
    margin: 0,
    fontSize: 18,
    textTransform: "uppercase",
    letterSpacing: "0.04em",
    lineHeight: 1.33,
  },
  CardLocation: {
    marginTop: 4,
    fontStyle: "italic",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: "0.056em",
    marginBottom: 0,
  },
  CardPhone: {
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: 14,
    lineHeight: 1.1,
    letterSpacing: "0.06em",
    fontFamily: theme.headerFont,
    marginTop: 12,
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
    "& img": {
      height: 14,
      marginRight: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        height: 18,
      },
    },
    "& a": {
      color: "inherit",
      textDecoration: "none",
    },
  },
  CardLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    color: theme.palette.primary.main,
    fontFamily: theme.headerFont,
    fontSize: 14,
    textTransform: "uppercase",
    textDecoration: "none",
    fontWeight: "bold",
    flex: "0 0 100%",
    borderTop: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
      flex: "1 0 auto",
    },
    "& img": {
      height: 15,
      marginLeft: 8,
    },
  },
  facilities: {
    fontSize: 12,
    fontStyle: "italic",
    "& p": {
      opacity: 0.44,
    },
    "& img": {
      marginLeft: 4,
      marginRight: 4,
    },
  },
  CardImageWrapper: {
    position: "relative",
    // nested selectors, on hover show CardImage2
    "&:hover $CardImageHover": {
      opacity: 1,
    },
  },
  CardImage: {
    width: "100%",
    display: "block",
    objectFit: "cover",
    aspectRatio: "16/9",
  },
  CardImageHover: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    width: "100%",
    opacity: 0,
    objectFit: "cover",
    aspectRatio: "16/9",
    transition: "opacity 0.2s ease-in-out",
  },
  SlickArrow: {
    position: "absolute",
    bottom: -40,
    width: 18,
    zIndex: 2,
    "&::after": {
      display: "none",
    },
  },
  SlickPrevious: {
    left: 16,
  },
  SlickNext: {
    right: 16,
  },
  SlickPaging: {
    margin: "0 24px",
    bottom: -25,
    padding: 0,
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: 14,
    fontFamily: theme.headerFont,
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
    },
    "& .slick-active": {
      color: theme.palette.common.black,
      borderBottom: "2px solid " + theme.palette.common.black,
      lineHeight: 1.5,
      height: "auto !important",
    },
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    gap: 24,
    width: "100%",
    padding: "60px 16px 40px",
    "& > button": {
      width: 18,
      height: 18,
      backgroundImage: `url(${arrow})`,
      backgroundSize: "cover",
      backgroundPosition: "center right",
      backgroundColor: "transparent",
      border: "none",
      fontSize: 0,
      "&:nth-of-type(1)": {
        transform: "rotate(180deg)",
      },
    },
    "& ul": {
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "space-between",
      padding: 0,
      margin: 0,
      "& li, & li button": {
        display: "block",
        padding: 0,
        fontFamily: theme.headerFont,
        color: theme.palette.primary.main,
        fontSize: 18,
        lineHeight: 1.5,
        fontWeight: "bold",
        padding: 0,
        background: "transparent",
        border: "none",
        borderRadius: 0,
        "&.active, &.active button": {
          color: theme.palette.common.black,
          borderBottom: `1px solid ${theme.palette.common.black}`,
        },
      },
    },
  },
});
