import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import RegionSelector from "../blocks/CgbFindPubBlock/RegionSelector";
import Facilities, {
  facilitiesList,
} from "../blocks/CgbFindPubBlock/Facilities";
import { useSearchResultsStore } from "../../services/store";
import styles from "./Filters.styles";
import arrowIcon from "../../images/right-arrow.png";

const useStyles = makeStyles((theme) => styles(theme));

function FiltersFull({ loadData, prefillValues = {} }) {
  const {
    searchQuery,
    setSearchQuery,
    selectedRegions,
  } = useSearchResultsStore();
  const [query, updateQuery] = useState(prefillValues.query);
  const [gettingLocation, setGettingLocation] = useState(false);
  const [useLocationText, setUseLocationText] = useState("Use my current location");
  const [radius, updateRadius] = useState(prefillValues.radius || "5");

  const [facilities, updateFacilities] = useState(() => {
    return facilitiesList.reduce((acc, facility) => {
      acc[facility.state] = prefillValues[facility.state] || false;
      return acc;
    }, {});
  });

  const classes = useStyles();

  function clearFilters(e) {
    e.preventDefault();

    updateQuery("");
    updateRadius(5);
    updateFacilities(
      facilitiesList.reduce((acc, facility) => {
        acc[facility.state] = false;
        return acc;
      }, {})
    );
  }

  function handleSubmit(e) {
    e.preventDefault();

    const data = {
      query,
      radius,
      regions: selectedRegions,
      ...facilities,
    };

    loadData(data);
  }

  function getCurrentLocation() {
    if (navigator.geolocation) {
      setGettingLocation(true);
      setUseLocationText("Getting location...");
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;

          await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&addressdetails=1`)
            .then((response) => response.json())
            .then((data) => {
              const { city, postcode } = data.address;
              updateQuery(city || postcode);
              setSearchQuery(city || postcode);
              setGettingLocation(false);
              setUseLocationText("Use my current location");
            })
            .catch((error) => {
              console.log(error);
              setUseLocationText("Your location could not be determined");
            });
        },
        function (error) {
          console.log(error);
          setUseLocationText("Your location could not be determined");
        }
      );
    } else {
      console.warn("Geolocation is not supported by this browser.");
      setGettingLocation(false);
      setUseLocationText("Your location could not be determined");
    }
  }

  return (
    <div className={`find-a-pub ${classes.FindPubWithMap}`}>
      <Grid container direction="row">
        <Grid item xs={12}>
          <div className={`form ${classes.FormWithMap}`}>
            <h3 className={classes.Legend}>Find a Pub</h3>
            <div className={`fields ${classes.Fields}`}>
              <div className={`${classes.Field} ${classes.Name}`} role="search" aria-disabled={selectedRegions.length > 0}>
                <label className={classes.Label} htmlFor="name">
                  Search by
                </label>
                <input
                  className={classes.Input}
                  type="search"
                  name="query"
                  placeholder="Pub Name or Town or Postcode"
                  autoComplete="off"
                  value={query ?? searchQuery ?? ""}
                  onChange={(e) => {
                    updateQuery(e.target.value);

                    setSearchQuery(e.target.value);
                  }}
                />
                <button>
                  <img src={arrowIcon} alt="Search" />
                </button>
              </div>
              <div className={classes.Location} onClick={getCurrentLocation} data-loading={gettingLocation} aria-disabled={selectedRegions.length > 0}>
                <button>{useLocationText}</button>
              </div>
              <div className={`or`}>Or</div>
              <div className={classes.Field} aria-disabled={searchQuery.length > 0}>
                <label className={classes.Label} htmlFor="regions">
                  By Region
                </label>
                <RegionSelector hasMap={true} />
              </div>
            </div>
            <Facilities
              classes={classes}
              prefillValues={facilities}
              updateFacilities={updateFacilities}
              clearFilters={clearFilters}
              hasMap={true}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default FiltersFull;
