import brassTexture from "../../images/brass-texture.jpg"; // eslint-disable-line
import dropArrow from "../../images/drop-arrow.png"; // eslint-disable-line
import filterIcon from "../../images/filter-icon.png"; // eslint-disable-line

import point from "../../images/location-point-light.png";

export default (theme) => ({
  FindPubWithMap: {
    position: "relative",
    padding: "24px 6px",
    marginBottom: 36,
  },
  FormWithMap: {
    position: "relative",
    zIndex: 1,
    padding: 16,
  },
  Legend: {
    fontSize: 24,
    textTransform: "uppercase",
    marginBottom: 40,
    marginTop: 20,
    letterSpacing: "0.056em",
    lineHeight: 1.25,
    [theme.breakpoints.up(1230)]: {
      marginTop: 36,
      fontSize: 32,
    },
    "&::before": {
      content: '""',
      display: "block",
      marginBottom: 20,
      width: "1.3em",
      borderBottom: `4px solid ${theme.palette.primary.main}`,
    },
  },
  Intro: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
      letterSpacing: "0.032em",
      lineHeight: 2,
      paddingRight: 80,
      marginTop: 0,
      display: "block",
    },
    [theme.breakpoints.up("lg")]: {
      paddingRight: 120,
    },
  },
  Label: {
    display: "block",
    marginBottom: 12,
    fontSize: 16,
    textTransform: "uppercase",
    fontFamily: theme.headerFont,
    lineHeight: 1,
    fontWeight: "bold",
    letterSpacing: "0.04em",
    [theme.breakpoints.up(1230)]: {
      fontSize: 18,
    },
  },
  Input: {
    display: "block",
    appearance: "none",
    width: "100%",
    padding: "10px 14px",
    marginRight: 20,
    color: "gray",
    backgroundColor: "white",
    border: 0,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    fontSize: 12,
    fontFamily: theme.bodyFont,
    lineHeight: 2,
    letterSpacing: "0.024em",
  },
  Radius: {
    backgroundImage: "url(" + dropArrow + ")",
    backgroundSize: 18,
    backgroundPosition: "center right 10px",
    backgroundRepeat: "no-repeat",
    paddingRight: 38,
  },
  Button: {
    display: "block",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: 14,
    border: "none",
    borderRadius: 4,
    textTransform: "uppercase",
    fontSize: 16,
    fontWeight: "bold",
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontFamily: theme.headerFont,
    letterSpacing: "0.06em",
    lineHeight: 1.15,
    textAlign: "left",
    marginBottom: 24,
    marginTop: 40,
    transition: "background-color .3s ease-in-out",
    "&:hover, &:focus": {
      backgroundColor: "#72ce9b",
    },
    "& img": {
      marginTop: 2,
      width: 14,
      float: "right",
      marginLeft: 20,
      verticalAlign: "middle",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
  },
  Fields: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "flex-end",
    },
    '&[data-simple="true"]': {
      [theme.breakpoints.up("md")]: {
        marginTop: 65,
      },
    },
  },
  Field: {
    display: "block",
    marginBottom: 16,
    [theme.breakpoints.up("md")]: {
      flex: "0 0 150px",
      paddingLeft: 32,
    },
    '&[data-simple="true"]': {
      [theme.breakpoints.up("md")]: {
        flex: "0 0 50%",
        paddingLeft: 0,
      },
    },
    // disabled styles
    "&[aria-disabled='true']": {
      opacity: 0.5,
      pointerEvents: "none",
    },
  },
  Name: {
    position: "relative",
    "& button": {
      width: 50,
      height: 47,
      bottom: 0,
      right: 0,
      position: "absolute",
      borderRadius: 0,
      fontSize: 0,
      backgroundColor: "#d4b4a5",
      border: "none",
      borderBottom: "3px solid " + theme.palette.primary.main,
      marginLeft: -1,
      "& img": {
        padding: 12,
      },
    },
    [theme.breakpoints.up("md")]: {
      flex: "0 0 calc(100% - 590px)",
      paddingLeft: 0,
    },
    '&[data-simple="true"]': {
      [theme.breakpoints.up("md")]: {
        flex: "1 0 100%",
      },
    },
  },
  Submit: {
    [theme.breakpoints.up("md")]: {
      flex: "0 0 200px",
      display: "flex",
      alignItems: "flex-end",
      marginBottom: 16,
      paddingLeft: 32,
    },
    '&[data-simple="true"]': {
      [theme.breakpoints.up("md")]: {
        flex: "0 0 50%",
        paddingLeft: 32,
      },
    },
  },
  Location: {
    textAlign: "center",
    "& button": {
      backgroundColor: "transparent",
      border: 0,
      color: theme.palette.primary.main,
      fontFamily: theme.bodyFont,
      padding: "0",
      fontSize: 15,
      lineHeight: 1.1,
      border: "0",
      margin: "10px 0",
      "&::before": {
        display: "inline-block",
        content: '""',
        width: 20,
        height: 20,
        backgroundSize: "20px",
        backgroundImage: `url(${point})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        verticalAlign: "bottom",
        marginRight: 10,
      },
    },
  },
  Results: {
    position: "absolute",
    zIndex: "10",
    top: 86,
    width: "100%",
    backgroundColor: "white",
    fontSize: 0,

    "& ul": {
      listStyle: "none",
      padding: "0 10px",
    },
    "& li": {
      padding: "6px 4px",
      fontSize: 14,
      "&:hover": {
        color: "white",
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  Facilities: {
    borderBottom: "2px solid " + theme.palette.primary.main,
    padding: "0 8px",
    marginBottom: 20,
    [theme.breakpoints.up("md")]: {
      padding: 0,
      marginTop: 16,
    },
  },
  FacilitiesHeader: {
    "& > button": {
      appearance: "none",
      backgroundColor: "transparent",
      display: "block",
      width: "100%",
      color: theme.palette.primary.main,
      fontFamily: theme.headerFont,
      padding: "0 0 10px",
      fontSize: 18,
      lineHeight: 1.25,
      letterSpacing: "0.06em",
      fontWeight: "bold",
      border: "0",
      textTransform: "uppercase",
      backgroundImage: "url(" + filterIcon + ")",
      backgroundPosition: "top right",
      backgroundRepeat: "no-repeat",
      backgroundSize: 19,
      textDecoration: "none",
      [theme.breakpoints.up("md")]: {
        width: "fit-content",
        paddingRight: 60,
        cursor: "default !important",
      },
    },
  },
  FacilitiesOptions: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
    '&[data-open="true"]': {
      display: "block",
    },
  },
  hidden: {
    display: "none",
  },
  Option: {
    position: "relative",
    display: "block",
    paddingLeft: 36,
    fontSize: 15,
    lineHeight: "26px",
    letterSpacing: "0.032em",
    marginBottom: 12,
    marginTop: 12,
    [theme.breakpoints.up("md")]: {
      display: "inline-block",
      marginRight: 24,
    },

    // disabled styles
    "&[data-disabled='true']": {
      opacity: 0.5,
      pointerEvents: "none",
    },
    "& img": {
      display: "inline-block",
      marginRight: 6,
      width: 25,
      verticalAlign: "top",
    },
    "& input": {
      position: "absolute",
      opacity: 0,
      cursor: "pointer",
      width: 0,
      height: 0,
      margin: 0,
      top: 0,
      right: 0,
    },
    "& .checkmark": {
      position: "absolute",
      cursor: "pointer",
      top: 0,
      left: 0,
      height: 24,
      width: 24,
      backgroundColor: theme.palette.common.white,
      border: "1px solid " + theme.palette.primary.main,
      "&::after": {
        content: '""',
        position: "absolute",
        display: "none",
        left: 7,
        top: 3,
        width: 5,
        height: 10,
        border: "solid " + theme.palette.primary.contrastText,
        borderWidth: "0 3px 3px 0",
        transform: "rotate(45deg)",
        boxSizing: "content-box",
      },
    },
    "&:hover input ~ .checkmark": {
      border: "1px solid " + theme.palette.primary.dark,
    },
    "& input:checked ~ .checkmark": {
      backgroundColor: theme.palette.primary.main,
    },
    "& input:checked ~ .checkmark::after": {
      display: "block",
    },
  },
  Clear: {
    padding: 0,
    fontFamily: theme.headerFont,
    color: theme.palette.common.black,
    paddingLeft: 30,
    letterSpacing: "0.04em",
    lineHeight: 1.25,
    textTransform: "uppercase",
    opacity: 0.5,
    border: "none",
    backgroundColor: "transparent",
    fontSize: 18,
    display: "table",
    margin: "0 auto 24px",
    position: "relative",
    outline: "none !important",
    [theme.breakpoints.up("md")]: {
      margin: "16px 0 16px 24px",
      float: "right",
    },
    "&::before, &::after": {
      width: 22,
      height: 2,
      position: "absolute",
      backgroundColor: theme.palette.common.black,
      left: 0,
      top: 10,
      transformOrigin: "50% 50%",
      transform: "rotate(45deg)",
      content: '""',
    },
    "&::after": {
      transform: "rotate(135deg)",
    },
  },
});
