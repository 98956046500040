import { create } from "zustand";

export const useSearchResultsStore = create((set, get) => ({
  searchPerformed: false,

  // store regular query results
  searchResults: [],
  setSearchResults: (searchResults) => set({ searchResults }),

  clearSearchResults: () => set({ searchResults: [] }),

  // store selected region groups in filter
  selectedRegions: [],

  setSelectedRegions: (selectedRegions) => {
    const state = get();
    const newSelectedRegions = Array.isArray(selectedRegions) ? selectedRegions.filter((region, index, arr) => arr.indexOf(region) === -1) : [];
    const searchQuery = (newSelectedRegions.length > 0) ? '' : state.searchQuery;
    set({
      searchQuery: searchQuery,
      selectedRegions: newSelectedRegions
    });
  },

  addSelectedRegion: (region) => {
    const state = get();
    if (!state.selectedRegions.includes(region)) {
      let newSelectedRegions = [...state.selectedRegions, region];
      set({
        selectedRegions: newSelectedRegions
      });
    }
  },
  removeSelectedRegion: (region) => {
    const state = get();
    const index = state.selectedRegions.indexOf(region);
    if (index !== -1) {
      let newSelectedRegions = [...state.selectedRegions];
      newSelectedRegions.splice(index, 1);
      set({
        selectedRegions: newSelectedRegions
      });
    }
  },
  clearSelectedRegions: () => set({ selectedRegions: [] }),

  // store selected facilities in filter
  selectedFacilities: [],
  addSelectedFacility: (facility) => {
    set((state) => {
      const selectedFacilities = [...state.selectedFacilities];
      if (!selectedFacilities.includes(facility)) {
        selectedFacilities.push(facility);
      }
      return { selectedFacilities };
    });
  },
  removeSelectedFacility: (facility) => {
    set((state) => {
      const selectedFacilities = [...state.selectedFacilities];
      if (selectedFacilities.includes(facility)) {
        const index = selectedFacilities.indexOf(facility);
        selectedFacilities.splice(index, 1);
      }
      return { selectedFacilities };
    });
  },
  clearSelectedFacilities: () => set({ selectedFacilities: [] }),

  // store search query in filter
  searchQuery: "",
  setSearchQuery: (searchQuery) => set({ searchQuery }),
  clearSearchQuery: () => set({ searchQuery: "" }),

  selectedPubTypes: [],
  addSelectedPubType: (pubType) => {
    set((state) => {
      const selectedPubTypes = [...state.selectedPubTypes];
      if (!selectedPubTypes.includes(pubType)) {
        selectedPubTypes.push(pubType);
      }
      return { selectedPubTypes };
    });
  },
  removeSelectedPubType: (pubType) => {
    set((state) => {
      const selectedPubTypes = [...state.selectedPubTypes];
      if (selectedPubTypes.includes(pubType)) {
        const index = selectedPubTypes.indexOf(pubType);
        selectedPubTypes.splice(index, 1);
      }
      return { selectedPubTypes };
    });
  },
  availableFacilities: [],
  setAvailableFacilities: (availableFacilities) => set({ availableFacilities }),
  searchRadius: 25,
  setSearchRadius: (searchRadius) => set({ searchRadius }),
  searchLocation: {},
  setSearchLocation: (searchLocation) => set({ searchLocation }),

  allPubs: [],
  setAllPubs: (allPubs) => set({ allPubs }),

  apiUrl: `${process.env.GATSBY_CRM_ENDPOINT_URL}houses?fields=basic,distance,urls,facilities`,

  search: async () => {
    set({ searchPerformed: false });
    const state = get();
    let newLocation = state.searchLocation;
    let locationSearchResults = [];
    let nameSearchResults = [];
    let newSearchResults = [];

    if (state.searchQuery) {
      const url = `${state.apiUrl}&location=geocode:${state.searchQuery},${state.searchRadius}&perPage=500`;
      await fetch(url, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + process.env.GATSBY_CRM_ENDPOINT_AUTH_TOKEN,
          "Content-Type": "application/json",
        }
      })
      .then((res) => res.json())
      .then((res) => {
        if (res?.search?.geocoding?.matches) {
          newLocation = res.search.geocoding.matches[0];
        } else {
          newLocation = null;
        }
        if (res.data) {
          // clean up data, convert snake_case to camelCase
          locationSearchResults = [];
          res.data.map((pub) => {
            Object.keys(pub).forEach((key) => {
              // check if key contains "facility_"
              if (key.includes("facility_")) {
                pub[key.replace(/_([a-z])/g, (g) => g[1].toUpperCase())] = pub[key];
                delete pub[key];
              }
            });

            const matchingPub = state.allPubs.find((aPub) => {
              return aPub.PubData.crmId === pub.id;
            });
            if (matchingPub) {
              locationSearchResults.push({ ...matchingPub, distance: pub.distance });
            }
          });
        }
      });

      //
      nameSearchResults = state.allPubs.filter((pub) => {
        const searchText = state.searchQuery
          .toLowerCase()
          .replace(/ and /g, ".*?")
          .replace(/^and /g, "")
          .replace(/ and$/g, "")
          .replace(/&/g, ".*?")
          .replace(/ the /g, ".*?")
          .replace(/^the /g, "")
          .replace(/ the$/g, "")
          .replace(/ /g, ".*?");
        const regex = new RegExp(searchText);

        return regex.test(pub.PubData.name.toLowerCase());
      });
      nameSearchResults.sort((a, b) => {
        return a.PubData.name.localeCompare(b.PubData.name);
      });

      newSearchResults = [...nameSearchResults, ...locationSearchResults].reduce((acc, current) => {
        const pubAlreadyInList = acc.find((item) => {
          return item.PubData.crmId === current.PubData.crmId
        });
        if (!pubAlreadyInList) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    } else {
      newSearchResults = state.allPubs;
    }
    newSearchResults = newSearchResults.filter((pub) => {
      if (pub.PubData.suppress) {
        return false;
      }
      if (state.selectedRegions?.length > 0) {
        if (!state.selectedRegions.includes(String(pub.PubData.regionGroupId ?? pub.PubData.region_group_id))) {
          return false;
        }
      }
      if (state.selectedFacilities?.length > 0) {
        if (
          !state.selectedFacilities.every((facility) => {
            // each facility is a separate item on the pub object i.e. pub.facilityAccommodation, pub.facilityFood, etc. so we need to check if any of them are true
            return Object.keys(pub.PubData).some((key) => {
              // check if the key is a facility and if it's true
              return key === facility && pub.PubData[key] === true;
            });
          })
        ) {
          return false;
        }
      }
      return true;
    });

    set({
      searchLocation: newLocation,
      searchResults: newSearchResults,
      searchPerformed: true,
    });
  },
}));
