import React, { useEffect, useMemo, useState, lazy } from "react";
import { withStyles } from "@material-ui/styles";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import { FindAPubResultsBlock } from "../components/FindAPub/results";
import { useSearchResultsStore } from "../services/store";

import point from "../images/location-point.png";
import arrow from "../images/link-arrow.png";
import map from "../images/icon-find-pub-white.png";
import list from "../images/list-icon.png";
import FiltersFull from "../components/FindAPub/FiltersFull";
import useDebounce from "../helpers/useDebounce";

const Map = lazy(() =>
  import("../components/blocks/CgbFindPubResultsBlock/Map")
);

const styles = (theme) => ({
  contentWrap: {
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {},
    "& h2": {
      [theme.breakpoints.up("md")]: {},
    },
  },
  Results: {
    padding: "32px 25px",
    [theme.breakpoints.up("md")]: {
      paddingInline: 48,
    },
    "& > div": {
      "& .showing": {
        marginTop: 0,
        marginBottom: 32,
        fontSize: 16,
        letterSpacing: "0.04em",
        fontWeight: "bold",
        lineHeight: 1.5,
        opacity: 0.5,
        fontFamily: theme.headerFont,
        textTransform: "uppercase",
      },
      "& > h3": {
        display: "none",
      },
    },
  },
  wrap: {
    width: "calc(100vw + 2px)",
    marginLeft: "calc(50% - 1px)",
    transform: "translateX(-50%)",
    overflowY: "scroll",
    overflowX: "hidden",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      height: "calc(100vh - 125px)",
      width: "calc(100vw - 18px)",
      direction: "rtl",
    },
    "& .find-a-pub": {
      padding: 0,
      backgroundColor: theme.palette.secondary.main,
      paddingTop: 40,
      paddingInline: 25,
      [theme.breakpoints.up("md")]: {
        paddingInline: 48,
      },
      "& .form": {
        padding: 0,
        "& > h3": {
          color: theme.palette.secondary.contrastText,
          fontSize: 40,
          fontWeight: "bold",
          lineHeight: 1.6,
          letterSpacing: "0.056em",
          marginBottom: 28,
          marginTop: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            fontSize: 56,
            lineHeight: "64px",
          },
          "&:before": {
            display: "none",
          },
        },
        "& .fields": {
          '& div[role="search"]': {
            display: "flex",
            flexWrap: "wrap",
            [theme.breakpoints.up("md")]: {
              flex: "0 0 100%",
            },
            "& label": {
              color: theme.palette.secondary.contrastText,
              textTrasnform: "uppercase",
              fontWeight: "bold",
              letterSpacing: "0.04em",
              fontSize: 18,
              lineHeight: 1.33,
              marginBottom: 10,
              flex: "0 0 100%",
            },
            "& input": {
              flex: "0 0 calc(100% - 49px)",
              marginRight: 0,
              border: "none",
              borderBottom: "3px solid " + theme.palette.primary.main,
            },
            "& button": {
              width: 50,
              flex: "0 0 50px",
              maxWidth: "50%",
              borderRadius: 2,
              fontSize: 0,
              backgroundColor: theme.palette.primary.main,
              border: "none",
              borderBottom: "3px solid " + theme.palette.primary.main,
              marginLeft: -1,
              outline: `1px solid ${theme.palette.primary.main}`,
              boxShadow: `0 0 15px rgba(0,0,0.25)`,
            },
          },
          "& label[for='radius']": {
            color: theme.palette.secondary.contrastText,
            textTrasnform: "uppercase",
            fontWeight: "bold",
            letterSpacing: "0.04em",
            fontSize: 18,
            lineHeight: 1.33,
            marginBottom: 10,
            marginTop: 10,
          },
          "& .or": {
            display: "flex",
            margin: "10px 0",
            color: theme.palette.secondary.contrastText,
            fontSize: 14,
            opacity: 0.3,
            alignItems: "center",
            justifyContent: "space-between",
            [theme.breakpoints.up("md")]: {
              flex: "0 0 100%",
            },
            "&::before, &::after": {
              width: "calc(50% - 40px)",
              height: 1,
              content: "''",
              display: "block",
              backgroundImage:
                "radial-gradient(circle at 1px 1px, #F1E9E4 1px, transparent 0)",
              backgroundSize: "8px 8px",
            },
          },
          "& > div": {
            paddingLeft: 0,
            [theme.breakpoints.up("md")]: {
              flex: "0 0 100%",
            },
          },
          "& label[for='regions']": {
            color: theme.palette.secondary.contrastText,
            textTrasnform: "uppercase",
            fontWeight: "bold",
            letterSpacing: "0.04em",
            fontSize: 18,
            lineHeight: 1.33,
            marginBottom: 10,
          },
          "& .select-mimic": {
            display: "none",
          },
          "& .dropdown-mimic": {
            display: "block !important",
            maxHeight: "none !important",
            opacity: 1,
            position: "relative",
            backgroundColor: "transparent",
            pointerEvents: "auto !important",
            marginBottom: 26,
            "& label": {
              position: "relative",
              display: "inline-block",
              width: "fit-content",
              fontSize: 0,
              padding: 0,
              overflow: "hidden",
              backgroundColor: "transparent",
              marginRight: 8,
              marginBottom: 8,

              "& span": {
                padding: "12px 22px",
                backgroundColor: "#FEFEFE",
                border: "1px solid " + theme.palette.primary.main,
                fontSize: 14,
                letterSpacing: "0.032em",
                borderRadius: 8,
                display: "block",
                transition: "all .3s ease-in-out",
              },
              "& input": {
                height: 1,
                width: 1,
                position: "absolute",
                top: -25,
                left: -25,
              },
              "&[data-active='true'] span, &:hover span": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              },
            },
          },
        },
        "& .facilities": {
          padding: 0,
          borderTop: "2px solid " + theme.palette.primary.main,
          marginBottom: 56,
          marginTop: 0,
          "& .header": {
            "& button": {
              paddingBlock: 14,
              textAlign: "left",
              backgroundImage: "none",
              position: "relative",
              cursor: "pointer !important",
              [theme.breakpoints.up("md")]: {
                width: "100%",
              },
              "&::after": {
                content: "''",
                display: "block",
                width: 18,
                height: 18,
                backgroundImage: `url(${arrow})`,
                backgroundSize: "cover",
                backgroundPosition: "center right",
                position: "absolute",
                right: 0,
                top: "calc(50% - 9px)",
                transition: "all .3s ease-in-out",
              },
              '&[data-open="true"]::after': {
                transform: "rotate(90deg)",
              },
            },
          },
          "& .toggles": {
            marginBottom: 24,
            [theme.breakpoints.up("md")]: {
              paddingLeft: 10,
            },
            "&::before": {
              content: "'Facilities'",
              color: theme.palette.common.white,
              textTransform: "uppercase",
              fontSize: 18,
              fontWeight: "bold",
              lineHeight: 1.333,
              marginBottom: 10,
              letterSpacing: "0.04em",
              fontFamily: theme.headerFont,
              display: "block",
            },
            "& button": {
              display: "none",
            },
            "& label": {
              display: "inline-block",
              width: "fit-content",
              marginTop: 0,
              marginBottom: 4,
              marginRight: 4,
              padding: 0,
              "& .checkmark": {
                display: "none",
              },
              "& .label": {
                borderRadius: 8,
                backgroundColor: "#FEFEFE",
                color: theme.palette.secondary.main,
                border: "1px solid " + theme.palette.primary.main,
                padding: "12px 34px 12px 14px",
                lineHeight: "18px",
                display: "flex",
                alignItems: "center",
                "& img": {
                  width: 18,
                  height: 18,
                  marginRight: 8,
                },
              },
            },
            "& :checked ~ .label": {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              "& img": {
                filter: "invert(1)",
              },
            },
            "& .pub-type": {
              marginTop: 24,
              "&::before": {
                content: "'Pub Type'",
                color: theme.palette.common.white,
                textTransform: "uppercase",
                fontSize: 18,
                fontWeight: "bold",
                lineHeight: 1.333,
                marginBottom: 10,
                letterSpacing: "0.04em",
                fontFamily: theme.headerFont,
                display: "block",
              },
              "& .select-mimic": {
                maxWidth: "calc(100% - 10px)",
              },
            },
          },
        },
      },
    },
  },
  map: {
    [theme.breakpoints.down("md")]: {
      '&[data-map-open="true"]': {
        display: "block",
        width: "100%",
        height: "calc(100vh - 65px)",
      },
    },
    [theme.breakpoints.up("md")]: {
      direction: "ltr",
      flex: "0 0 calc(100% - 320px)",
      position: "sticky",
      top: 0,
      right: 0,
      bottom: 0,
      left: 320,
    },
    [theme.breakpoints.up("lg")]: {
      flex: "0 0 calc(100% - 480px)",
      left: 480,
    },
    "& .mapboxgl-popup": {
      zIndex: 0,
      "& .mapboxgl-popup-tip": {
        opacity: 0,
      },
      "& .mapboxgl-popup-content": {
        backgroundColor: "#F1E9E4",
        padding: 0,
        borderRadius: 0,
        boxShadow: "4px 16px 16px rgba(0,0,0,0.16)",
        width: 280,
        minWidth: 280,
        "& img": {
          objectFit: "cover",
          objectPostion: "center",
          aspectRatio: "280/180",
          display: "block",
          width: "100%",
        },
        "& h3": {
          paddingInline: 24,
          marginBottom: 6,
          fontSize: 16,
          fontWeight: "bold",
          lineHeight: 1.5,
          letterSpacing: "0.04em",
          textTransform: "uppercase",
          fontFamily: theme.headerFont,
          position: "relative",
          zIndex: 1,
          background:
            "linear-gradient(180deg, rgba(241,233,228,0) 0%, rgba(241,233,228,.45) 45%, rgba(241,233,228,.94) 75%, rgba(241,233,228,1) 100%)",
          paddingTop: 40,
          color: "#0E0E0E",
          marginTop: -64,
        },
        "& p": {
          paddingInline: 24,
          marginTop: 0,
          marginBottom: 32,
          color: "#0E0E0E",
          fontStyle: "italic",
          fontSize: 14,
          fontFamily: theme.bodyFont,
        },
        "& a": {
          fontFamily: theme.headerFont,
          fontWeight: "bold",
          color: "#A15A38",
          letterSpacing: "-0.008em",
          fontSize: 16,
          lineHeight: 1,
          padding: 14,
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          textTransform: "uppercase",
          borderTop: "1px solid #A15A38",
          "&::after": {
            content: "''",
            backgroundImage: `url(${arrow})`,
            backgroundSize: 33,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: 34,
            height: 16,
            display: "block",
            marginLeft: 4,
          },
        },
        "& button": {
          color: "black",
          fontSize: 32,
          padding: 0,
          lineHeight: 1,
          width: 32,
        },
      },
    },
  },
  results: {
    '&[data-map-open="true"]': {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block !important",
      flex: "0 0 320px",
      position: "relative",
      boxShadow: "16px 3px 32px rgba(0,0,0,0.16)",
      zIndex: "10",
      height: "fit-content",
      direction: "ltr",
      marginLeft: 0,
      marginRight: "calc(100% - 320px)",
    },
    [theme.breakpoints.up("lg")]: {
      flex: "0 0 480px",
      marginRight: "calc(100% - 480px)",
    },
  },
  toggle: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    border: "2px solid #C6A693",
    borderRadius: 14,
    backgroundColor: "#0E0E0E",
    color: "#F1E9E4",
    display: "flex",
    padding: "12px 20px",
    textTransform: "uppercase",
    fontWeight: "bold",
    letterSpacing: "0.04em",
    justifyContent: "center",
    fontFamily: theme.headerFont,
    lineHeight: "18px",
    boxShadow: "-7px -1px 18px rgba(0,0,0,0.60)",
    position: "fixed",
    left: "50vw",
    zIndex: 10,
    bottom: 80,
    transform: "translateX(-50%)",
    '&[data-open="true"]': {
      color: "#0E0E0E",
      backgroundColor: "#F1E9E4",
    },
    "& img": {
      objectFit: "contain",
      width: 18,
      height: 18,
      marginRight: 10,
      objectPosition: "center",
    },
  },
});

function SearchResults({ classes, data }) {
  const [pubs] = useState(
    data.allWpPub.nodes.map((pub) => {
      pub.PubData.id = pub.id;
      pub.PubData.link = pub.link;
      pub.PubData.url_featured_image = pub.PubData.featuredImage;
      return pub;
    })
    .sort((a, b) => {
      return a.PubData?.name.localeCompare(b.PubData?.name);
    })
  );
  const [mapOpen, setOpenMap] = useState(false);
  const {
    selectedRegions,
    selectedFacilities,
    searchQuery,
    searchResults,
    setAvailableFacilities,
    searchRadius,
    search,
    searchPerformed,
  } = useSearchResultsStore();
  const [mapObject, setMapObject] = useState();

  useEffect(() => {
    search();
  }, [selectedFacilities, selectedRegions]);

  const [, cancel] = useDebounce(() => {
      search();
    },
    1500,
    [searchQuery, searchRadius]
  );

  const availableFacilities = useMemo(() => {
    const facilities = new Set();
    pubs.forEach((pub) => {
      Object.keys(pub?.PubData).forEach((key) => {
        if (key.includes("facility") && pub[key]) {
          facilities.add(key);
        }
      });
    });
    return facilities;
  }, [pubs]);

  useEffect(() => {
    setAvailableFacilities(Array.from(availableFacilities));
  }, [availableFacilities, setAvailableFacilities]);

  const mapInitialised = (mapData) => {
    setMapObject(mapData);
  };

  const [mapResized, setMapResized] = useState(false);
  useEffect(() => {
    if (mapOpen && mapObject && !mapResized) {
      mapObject.resize();
      search();
      setMapResized(true);
    }
  }, [mapOpen]);

  const mapListToggle = () => {
    setOpenMap(!mapOpen)
    if (typeof window !== "undefined") {
      window.scrollTo({top: 0});
    }
  }

  return (
    <Layout meta={{}} title="Search results">
      <button
        className={classes.toggle}
        onClick={() => mapListToggle()}
        data-open={mapOpen}
      >
        {mapOpen ? (
          <>
            <img src={list} alt="" />
            <span>List</span>
          </>
        ) : (
          <>
            <img src={map} alt="" />
            <span>Map</span>
          </>
        )}
      </button>
      <div className={classes.wrap}>
        <div className={classes.results} data-map-open={mapOpen}>
          <FiltersFull />

          <section className={classes.Results}>
            <FindAPubResultsBlock
              results={searchResults}
              pubs={pubs}
            />
          </section>
        </div>
        <div className={classes.map} data-map-open={mapOpen}>
          <Map
            pubs={pubs}
            classes={classes}
            className={classes.Map}
            mapInitialised={mapInitialised}
            fullScreen={true}
          />
        </div>
      </div>
    </Layout>
  );
}

export default withStyles(styles)(SearchResults);

export const query = graphql`
  query SearchResultsQuery {
    allWpPub {
      nodes {
        id
        PubData {
          availableForTenancy
          backgroundColor
          brand
          crmId
          facilityAccommodation
          facilityBeerGarden
          facilityDogFriendly
          facilityFood
          facilityFreeParking
          facilityGreatWalks
          facilityWifi
          facilityWheelchairAccessible
          facilityByTheSea
          facilityBusinessStays
          facilityEvChargingNearby
          facilityFamilyFriendly
          facilityAcceptingGiftCards
          hasOwnWebsite
          name
          featuredImage
          localFile {
            id
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 920
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
          url
          latitude
          longitude
          address2
          telephone
          displayLocation
          regionGroupId
          suppress
          googlePlaceId
        }
        link
      }
    }
  }
`;
