import React from "react";
import { Link as TrackedLink } from "../Link";
import { Link as GatsbyLink } from "gatsby";
import { trackOutboundLink } from "../../services/gtm";

import arrowImg from "../../images/link-arrow.png";
import phoneImg from "../../images/icon-phone-black.png";
import iconFood from "../../images/icon-food.png";
import iconAccom from "../../images/icon-stay.png";
import iconDog from "../../images/icon-dog-friendly.png";
import iconGarden from "../../images/icon-beer-garden.png";
import wifiIcon from "../../images/icon-wifi.png";
import walkIcon from "../../images/icon-great_walks.png";
import businessIcon from "../../images/icon-business_stay.png";
import bySeaIcon from "../../images/icon-by_the_sea.png";
import evIcon from "../../images/icon-ev_charger_near.png";
import wheelchairIcon from "../../images/icon-wheelchair.png";
import giftCardIcon from "../../images/icon-gift_cards.png";
import familyFriendlyIcon from "../../images/icon-family_friendly.png";
import freeParkingIcon from "../../images/icon-free_parking.png";
import { isInternalLink } from "../../helpers";

export default function Pub({ classes = {}, pub, featuredImage, ...rest }) {
  function Link({ url, children }) {
    if (!url) {
      return null;
    }

    const isExternalLink = pub.hasOwnWebsite || !isInternalLink(url);

    const linkProps = {
      className: classes.CardLink,
      onClick: () => trackOutboundLink(pub.pubUrl || pub?.url),
    };

    if (isExternalLink) {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer" {...linkProps}>
          {children}
        </a>
      );
    }

    return (
      <GatsbyLink to={url} {...linkProps}>
        {children}
      </GatsbyLink>
    );
  }

  return (
    <div className={classes.Card}>
      <div className={classes.CardUpper}>
        <div className={classes.CardImageWrapper}>
          <img
            src={featuredImage}
            className={classes.CardImage}
            alt={pub.name}
            width="1345"
            height="730"
          />
          {pub?.url_accommodation_image && (
            <img
              src={pub?.url_accommodation_image}
              className={classes.CardImageHover}
              alt={pub.name}
              width="1345"
              height="730"
            />
          )}
        </div>
        <span className={classes.CardInfo}>
          {pub?.distance && (
            <p className={classes.CardDistance}>
              {pub?.distance.miles} <span>miles</span>
            </p>
          )}
          {pub.facilityAccommodation && (
            <p className={classes.Tenancy}>Available</p>
          )}
        </span>
      </div>
      <div className={classes.CardBottom}>
        <h3 className={classes.CardName}>{pub.name}</h3>
        <p className={classes.CardLocation}>{pub.displayLocation}</p>
        {pub.telephone && (
          <p className={classes.CardPhone}>
            <img src={phoneImg} alt="Phone" />
            <a href={`tel:${pub.telephone}`} alt="Phone number">
              {pub.telephone}
            </a>
          </p>
        )}
        <Facilities
          classes={classes}
          facilities={{
            facilityFood: pub.facilityFood,
            facilityAccommodation: pub.facilityAccommodation,
            facilityDogFriendly: pub.facilityDogFriendly,
            facilityBeerGarden: pub.facilityBeerGarden,
            facilityFreeParking: pub.facilityFreeParking,
            facilityFamilyFriendly: pub.facilityFamilyFriendly,
            facilityWifi: pub.facilityWifi,
            facilityGreatWalks: pub.facilityGreatWalks,
            facilityAcceptingGiftCards: pub.facilityAcceptingGiftCards,
            facilityWheelchairAccessible: pub.facilityWheelchairAccessible,
            facilityByTheSea: pub.facilityByTheSea,
            facilityEvChargingNearby: pub.facilityEvChargingNearby,
            facilityBusinessStays: pub.facilityBusinessStays,
          }}
        />
      </div>

      <div
        className={`actions`}
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Link url={pub?.pubUrl || pub?.url}>
          View Pub
          <img src={arrowImg} alt="" />
        </Link>
        {pub?.url_website_book_room && (
          <Link url={pub?.url_website_book_room}>
            Book a Room
            <img src={arrowImg} alt="" />
          </Link>
        )}
      </div>
    </div>
  );
}

function Facilities({ facilities, classes }) {
  // {showFacilities && (
  const icons = [
    {
      icon: iconFood,
      alt: "Food Avaliable",
      show: facilities?.facilityFood,
    },
    {
      icon: iconAccom,
      alt: "Accommodation Avaliable",
      show: facilities?.facilityAccommodation,
    },
    {
      icon: iconDog,
      alt: "Dog Friendly",
      show: facilities?.facilityDogFriendly,
    },
    {
      icon: iconGarden,
      alt: "Beer Garden",
      show: facilities?.facilityBeerGarden,
    },
    {
      icon: freeParkingIcon,
      alt: "Free Parking",
      show: facilities?.facilityFreeParking,
    },
    {
      icon: familyFriendlyIcon,
      alt: "Family Friendly",
      show: facilities?.facilityFamilyFriendly,
    },
    {
      icon: wifiIcon,
      alt: "Wifi",
      show: facilities?.facilityWifi,
    },
    {
      icon: walkIcon,
      alt: "Great Walks",
      show: facilities?.facilityGreatWalks,
    },
    {
      icon: giftCardIcon,
      alt: "Accepting Gift Cards",
      show: facilities?.facilityAcceptingGiftCards,
    },
    {
      icon: wheelchairIcon,
      alt: "Wheelchair Accessible",
      show: facilities?.facilityWheelchairAccessible,
    },
    {
      icon: bySeaIcon,
      alt: "By The Sea",
      show: facilities?.facilityByTheSea,
    },
    {
      icon: evIcon,
      alt: "EV Charging Nearby",
      show: facilities?.facilityEvChargingNearby,
    },
    {
      icon: businessIcon,
      alt: "Business Stays",
      show: facilities?.facilityBusinessStays,
    },
  ];
  return (
    <div className={classes.facilities}>
      {icons.some(({ show }) => show) && <p>Facilities:</p>}
      <div className="icons">
        {icons.map(({ icon, alt, show }) => {
          if (!show) {
            return null;
          }

          return <img key={alt} src={icon} alt={alt} width={20} />;
        })}
      </div>
    </div>
  );
}
