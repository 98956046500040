import React from "react";
import Slider from "react-slick/lib";
import sliderArrow from "../../images/slider-arrow.png";

export default function Component({ classes, children }) {
  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        className={`${classes.SlickPrevious} ${classes.SlickArrow}`}
        onKeyDown={onClick}
        onClick={onClick}
        role="button"
        tabIndex={0}
      >
        <img
          src={sliderArrow}
          alt="Previous"
          style={{ transform: "rotate(180deg)" }}
        />
      </div>
    );
  }

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <div
        className={`${classes.SlickNext} ${classes.SlickArrow}`}
        onKeyDown={onClick}
        onClick={onClick}
        role="button"
        tabIndex={-1}
      >
        <img src={sliderArrow} alt="Next" />
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    draggable: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div
        style={{
          top: "100%",
          // bottom: -40
        }}
      >
        <ul className={classes.SlickPaging}>{dots}</ul>
      </div>
    ),
    customPaging: (i) => <div>{i + 1}</div>,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <Slider
      {...settings}
      style={{ width: "calc(100% + 20px)", margin: "0 -10px 80px" }}
    >
      {children}
    </Slider>
  );
}
