import React, { useEffect } from "react";
import foodIcon from "../../../images/icon-food.png";
import accomIcon from "../../../images/icon-stay.png";
import dogIcon from "../../../images/icon-dog-friendly.png";
import gardenIcon from "../../../images/icon-beer-garden.png";
import wifiIcon from "../../../images/icon-wifi.png";
import walkIcon from "../../../images/icon-great_walks.png";
import businessIcon from "../../../images/icon-business_stay.png";
import bySeaIcon from "../../../images/icon-by_the_sea.png";
import evIcon from "../../../images/icon-ev_charger_near.png";
import wheelchairIcon from "../../../images/icon-wheelchair.png";
import giftCardIcon from "../../../images/icon-gift_cards.png";
import familyFriendlyIcon from "../../../images/icon-family_friendly.png";
import freeParkingIcon from "../../../images/icon-free_parking.png";
import { useSearchResultsStore } from "../../../services/store";
import { removeWhitespace } from "../../../helpers";

export const facilitiesList = [
  {
    name: "food",
    icon: foodIcon,
    label: "Food",
    state: "requireFood",
  },
  {
    name: "accommodation",
    icon: accomIcon,
    label: "Accommodation",
    state: "requireAccommodation",
  },
  {
    name: "dog_friendly",
    icon: dogIcon,
    label: "Dog Friendly",
    state: "requireDogFriendly",
  },
  {
    name: "beer_garden",
    icon: gardenIcon,
    label: "Beer Garden",
    state: "requireBeerGarden",
  },
  {
    name: "free_parking",
    icon: freeParkingIcon,
    label: "Free Parking",
    state: "requireFreeParking",
  },
  {
    name: "family_friendly",
    icon: familyFriendlyIcon,
    label: "Family Friendly",
    state: "requireFamilyFriendly",
  },
  {
    name: "wifi",
    icon: wifiIcon,
    label: "Wifi",
    state: "requireWifi",
  },
  {
    name: "great_walks",
    icon: walkIcon,
    label: "Great Walks",
    state: "requireGreatWalks",
  },
  {
    name: "accepting_gift_cards",
    icon: giftCardIcon,
    label: "Accepts Gift Cards",
    state: "requireAcceptingGiftCards",
    key: "facilityAcceptingGiftCards",
  },
  {
    name: "wheelchair_accessible",
    icon: wheelchairIcon,
    label: "Wheelchair Accessible",
    state: "requireWheelchairAccessible",
  },
  {
    name: "by_the_sea",
    icon: bySeaIcon,
    label: "By The Sea",
    state: "requireByTheSea",
  },
  {
    name: "ev_charging_nearby",
    icon: evIcon,
    label: "EV Charging Nearby",
    state: "requireEvChargingNearby",
    key: "facilityEvChargingNearby",
  },
  {
    name: "business_stays",
    icon: businessIcon,
    label: "Business Stays",
    state: "requireBusinessStays",
  },
];

export default function Facilities({
  classes,
  updateFacilities,
  hasMap = false,
}) {
  const [open, setOpen] = React.useState(false);
  const toggleFacilties = e => {
    e.preventDefault();
    setOpen(!open);
  };
  const {
    selectedFacilities,
    addSelectedFacility,
    removeSelectedFacility,
    clearSelectedFacilities,
    availableFacilities,
    setAvailableFacilities,
  } = useSearchResultsStore();

  useEffect(() => {
    if (availableFacilities.length > 0) {
      setAvailableFacilities([]);
    }
  }, [availableFacilities]);

  const label = hasMap ? "Refine" : "Filter Facilities";

  return (
    <div className={`facilities ${classes.Facilities}`}>
      <div className={`header ${classes.FacilitiesHeader}`}>
        <button data-open={open} onClick={e => toggleFacilties(e)}>
          {label}
        </button>
      </div>
      <div
        className={`toggles ${classes.FacilitiesOptions} ${
          !open ? classes.hidden : ""
        }`}
        data-open={open}
      >
        {facilitiesList.map((facility, index) => {
          const item = facility.key ? facility.key : `facility${removeWhitespace(facility.label)}`;

          if (
            availableFacilities.length > 0 &&
            !availableFacilities.includes(item)
          ) {
            // return null;
          }
          return (
            <label
              key={index}
              className={classes.Option}
              data-disabled={
                availableFacilities.length > 0 &&
                !availableFacilities.includes(item)
              }
            >
              <input
                type="checkbox"
                name={facility.name}
                // defaultChecked={prefillValues[facilityKey]}
                checked={selectedFacilities.includes(item)}
                onChange={e => {
                  let target = e.target;
                  updateFacilities(prev => ({
                    ...prev,
                    [facility.state]: target?.checked || false,
                  }));
                  // check if the facility is already in the list
                  // if it is, remove it
                  if (selectedFacilities.includes(item)) {
                    removeSelectedFacility(item);
                  } else {
                    addSelectedFacility(item);
                  }
                }}
              />
              <span className="checkmark"></span>
              <span className="label">
                <img src={facility.icon} alt={facility.label} />
                {facility.label}
              </span>
            </label>
          );
        })}
        <button
          type="button"
          className={classes.Clear}
          onClick={() => clearSelectedFacilities()}
        >
          Clear Filter
        </button>
        {/* {hasMap && <PubTypeSelector />} */}
      </div>
    </div>
  );
}
