import React, { useEffect, useState } from "react";

export const usePagination = (items, itemsPerPage = 10, parentRef) => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (parentRef && parentRef.current) {
      parentRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      console.error("No parentRef provided to usePagination");
    }
  }, [currentPage, parentRef]);

  if (!items) {
    console.error("No items provided to usePagination");
    return { items: [], currentPage, totalPages: 0, setCurrentPage };
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(items.length / itemsPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const goToPrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const resetPagination = () => {
    setCurrentPage(1);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 3; // maximum number of page numbers to show at a time
    const ellipsis = <li>...</li>;

    if (totalPages <= maxPageNumbers) {
      // show all page numbers if there are less than or equal to the maximum
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <li key={i} className={i === currentPage ? "active" : null}>
            <button onClick={() => handleClick(i)}>{i}</button>
          </li>
        );
      }
    } else {
      let startPage = currentPage - Math.floor(maxPageNumbers / 2);
      let endPage = currentPage + Math.floor(maxPageNumbers / 2);

      if (startPage < 1) {
        endPage += Math.abs(startPage) + 1;
        startPage = 1;
      }

      if (endPage > totalPages) {
        startPage -= endPage - totalPages;
        endPage = totalPages;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <li key={i} className={i === currentPage ? "active" : null}>
            <button onClick={() => handleClick(i)}>{i}</button>
          </li>
        );
      }

      if (startPage > 1) {
        if (currentPage > Math.floor(maxPageNumbers / 2) + 1) {
          pageNumbers.unshift(ellipsis);
        }
        pageNumbers.unshift(
          <li key={1}>
            <button onClick={() => handleClick(1)}>1</button>
          </li>
        );
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pageNumbers.push(ellipsis);
        }
        pageNumbers.push(
          <li key={totalPages}>
            <button onClick={() => handleClick(totalPages)}>
              {totalPages}
            </button>
          </li>
        );
      }
    }

    return pageNumbers;
  };

  return {
    currentItems,
    currentPage,
    renderPageNumbers,
    goToNextPage,
    goToPrevPage,
    resetPagination,
  };
};
