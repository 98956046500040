import React, { useEffect } from "react";
import { withStyles } from "@material-ui/styles";

import styles from "./results.styles";
import PubList from "./PubList";
import { useSearchResultsStore } from "../../services/store";

const FindAPubResults = (props) => {
  const {
    searchRadius,
    searchResults,
    setSearchResults,
    searchLocation,
    searchPerformed,
    searchQuery,
  } = useSearchResultsStore();

  useEffect(() => {
    if (props.results) {
      setSearchResults(props.results);
    }
  }, [props.results, setSearchResults]);

  if (searchPerformed) {
    return (
      <div className={props.classes.results}>
        <h3 className={props.classes.Header}>
          Location:<span className={props.classes.Underline}></span>
        </h3>
        {searchResults?.length > 0 ? (
          <PubList
            pubs={props.results}
            classes={props.classes}
            grid
            searchRadius={searchRadius}
            searchLocation={searchLocation}
            searchQuery={searchQuery}
          />
        ) : (
          <div className={``}>
            <h2>No results</h2>
            <p>
              Sadly we can't find the pub, hotel or inn that you're looking for{" "}
              {searchLocation ? `near ${searchLocation?.address}` : ""}
            </p>
            <p>
              If you're still struggling please try choosing a different location
              or widen your radius.
            </p>
          </div>
        )}
      </div>
    );
  } else {
    return <div>Loading...</div>
  }
};

export const FindAPubResultsBlock = withStyles(styles)(FindAPubResults);
